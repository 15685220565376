import { template as template_eb38407742ee443fa0992dd108e6424f } from "@ember/template-compiler";
const FKControlMenuContainer = template_eb38407742ee443fa0992dd108e6424f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
