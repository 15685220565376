import { template as template_091ff31ab00b4ef1948c0c4280fd7073 } from "@ember/template-compiler";
const SidebarSectionMessage = template_091ff31ab00b4ef1948c0c4280fd7073(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
