import { template as template_b5589e8bd0b1435dafd344565e808a84 } from "@ember/template-compiler";
const FKLabel = template_b5589e8bd0b1435dafd344565e808a84(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
